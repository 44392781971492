.pagination {
  display: flex;
  align-items: center;
  gap: 10px;
  list-style: none;
  outline: none;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  font-family: 'Poppins', sans-serif;
}

.pagination>.active>a {
  background-color: #0F999B;
  border-color: #0F999B;
  color: #fff;
}

.pagination>li>a {
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  min-width: 30px;
  min-height: 30px;
  border: 1px solid #DBDBDB;
  border-radius: 5px !important;

  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  background-color: #0F999B;
  border-radius: 8px;
  color: #fff !important;
  outline: none;
}

.pagination>li>a,
.pagination>li>span {
  color: #ACACAC !important;
  border-radius: 8px;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-radius: unset;
}