@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Roboto:wght@400;500;700&display=swap');

body {
    font-family: 'Dancing Script', cursive;
    font-family: 'Roboto', sans-serif;
}

#dropdown-basic:hover,
#dropdown-basic:focus {
    background-color: transparent !important;
}

.content-sum {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    font-size: 14px;
    background-color: #f8fafc;
    padding: 6px 10px;
    border-radius: 6px;
}

.group-checkbox-filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    background: #f7f9fc;
    border: 1px solid var(--border-color-primary);
    border-radius: 6px 0 0 6px;
    padding: 12px 30px 12px 20px;
}

.input-filter {
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #d7e2ea !important;
    border-radius: 6px;
    padding: 12px 30px 12px 20px;
    width: 400px !important;
    margin-left: -1px;
}

.icon-search-filter {
    position: absolute;
    top: 30%;
    right: 0;
    margin-right: 10px;
}

.ck-editor__editable {
    min-height: 300px !important;
}

#navbarScrollingDropdown,
.link-text {
    padding: 15px 20px !important;
    color: #303030 !important;
}

a:hover,
a.ps-active.ps-open {
    background-color: #0f999b !important;
    border-radius: 10px !important;
    color: #ffffff !important;
}
.ps-sidebar-container {
    background-color: #fff !important;
}
.modal-content {
    border: none !important;
    border-radius: 10px !important;
}