.horizontal-scroll-container {
    overflow: hidden;
    width: 1550px;
    white-space: nowrap;
    overflow-x: auto;
    display: flex;
    align-items: center;
    padding: 10px;
    user-select: none;
}

.horizontal-scroll-content {
    display: inline-flex;
    gap: 0.5rem !important;
}